/* Start The Main Left Sidebar */
.the-main-sidebar {
    width: 225px;
    height: 100vh;
    background-color: #fff;
    z-index: 1;
}
.the-main-sidebar .icon {
    min-width: 45px;
    color: #A3A4A6;
    transition: var(--tran-03);
}
.the-main-sidebar .nav-text {
    color: #A3A4A6;
    font-size: 15px;
    font-weight: 500;
}
.the-main-sidebar .nav-link .nav-link-item {
    transition: var(--tran-03);
}
.the-main-sidebar .nav-link .active .icon,
.the-main-sidebar .nav-link .active .nav-text,
.the-main-sidebar .nav-link .nav-link-item:hover .icon,
.the-main-sidebar .nav-link .nav-link-item:hover .nav-text {
    color: var(--purple-color);
}
.the-main-sidebar .nav-link .nav-link-item.active {
    background-color: #F0EDFE;
}
.the-main-sidebar .sidebar-logo-holder {
    height: 70px;
    border-bottom: 1px solid #ebebff;
}
.the-main-sidebar .sidebar-logo-holder .logo-img {
    width: 150px;
}
/* End The Main Left Sidebar */

/* Start The Main Right Content Holder */
.the-main-content-holder {
    transition: all ease .3s;
    background-color: var(--gray-bg-color);
}
.copyright-text {
    padding-bottom: 70px;
}
@media (max-width: 991.99px) {
    .the-main-content {
        margin-bottom: 60px !important;
    }
}
@media (min-width: 992px) {
    .the-main-content-holder {
        padding-right: 225px;
    }
    .copyright-text {
        padding-bottom: 0;
    }
}
/* End The Main Right Content Holder */

/* Start The Main Header */
.the-main-header {
    height: 70px;
    border-bottom: 1px solid #ebebff;
    border-right: 1px solid #ebebff;
    min-height: 70px;
}
.the-main-header .page-title {
    font-size: 24px;
}
.the-main-header .header-logo-holder .logo-img {
    width: 120px;
}
.the-main-header .profile-link {
    font-size: 14px;
    color: var(--purple-color);
}
.the-main-header .profile-link:hover {
    color: var(--hover-color);
}
.the-main-header .copy-btn {
    border: 1px solid var(--purple-color);
    transition: var(--tran-03);
}
.the-main-header .copy-btn:hover {
    border-color: var(--hover-color);
}
/* End The Main Header */

/* Start The Main Content */
.the-main-content {
    flex-basis: 0;
    flex-grow: 1;
}
/* End The Main Content */

/* Start The Main Bottom Navbar */
.the-main-bottom-navbar {
    height: 70px;
    background-color: #fff;
    z-index: 1;
    border-top: 1px solid #ebebff;
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link {
    width: calc(100% / 5);
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .icon {
    color: #A3A4A6;
    transition: var(--tran-03);
    height: 30px;
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .icon svg {
    width: 25px;
    height: 25px;
    transition: var(--tran-03);
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .nav-text {
    font-weight: 500;
    font-size: 10px;
    color: #A3A4A6;
    transition: var(--tran-03);
}
.the-main-bottom-navbar .bottom-navbar-links .nav-link .active .icon,
.the-main-bottom-navbar .bottom-navbar-links .nav-link .active .nav-text,
.the-main-bottom-navbar .bottom-navbar-links .nav-link .nav-link-item:hover .icon,
.the-main-bottom-navbar .bottom-navbar-links .nav-link .nav-link-item:hover .nav-text {
    color: var(--purple-color);
}
/* End The Main Bottom Navbar */

/* Start Order Page */
.admin-order-page .authentication-content .form-group textarea,
.admin-order-page .authentication-content .form-group input {
    border-radius: 0.4rem;
    background: #fff;
    color: #000000;
    text-indent: 25px;
    caret-color: var(--purple-color);
    font-size: 14px;
}
.admin-order-page .authentication-content .form-group select,
.admin-order-page .css-13cymwt-control,
.admin-order-page .authentication-content .form-group input {
    height: 40px;
}
.admin-order-page .authentication-content .custom-searchable-select,
.admin-order-page .css-13cymwt-control {
    border-color: #dee2e6;
    border-radius: 0.4rem;
    color: #000000;
    text-indent: 5px;
    caret-color: var(--purple-color);
    font-size: 14px;
}
.admin-order-page .css-1jqq78o-placeholder {
    color: #000;
}

.admin-order-page .authentication-content .custom-searchable-select.is-empty-input,
.admin-order-page .is-empty-input .css-13cymwt-control {
    border-color: rgb(220,53,69) !important;
}
.admin-order-page .authentication-content .react-international-phone-country-selector-button {
    border-radius: 0.75rem 0 0 0.75rem;
    background: #fff;
    color: #000000;
    height: 56px;
    caret-color: var(--purple-color);
    font-size: 14px;
    padding: 0 15px;
}

.admin-order-page .authentication-content input::placeholder {
    color: #cdced3;
}

.admin-order-page .authentication-content textarea:focus,
.admin-order-page .authentication-content select:focus,
.admin-order-page .authentication-content .custom-searchable-select:focus,
.admin-order-page .authentication-content input:focus {
    outline: 0;
    border-color: var(--purple-color);
    box-shadow: none;
}
.admin-order-page .css-t3ipsp-control:hover,
.admin-order-page .css-t3ipsp-control {
    height: 40px;
    border-radius: 0.4rem;
    border-color: var(--purple-color);
    box-shadow: none;
}

.admin-order-page .authentication-content .submit-button {
    background-color: var(--purple-color);
    color: #ffffff;
    height: 40px;
    border-radius: 0.4rem;
    transition: 0.3s;
}

.admin-order-page .authentication-content .submit-button:hover {
    background-color: var(--hover-color);
}

.admin-order-page .auth-link {
    color: var(--blue-color);
}

.admin-order-page .auth-link:hover {
    color: var(--purple-color);
}
.admin-order-page .imgs-hoder .upload-box-holder .icon-holder {
    transition: var(--tran-03);
    background-color: var(--gray-text-color);
    color: #fff;
}
.admin-order-page .imgs-hoder .upload-box-holder .upload-text {
    transition: var(--tran-03);
    color: var(--gray-text-color);
}
.admin-order-page .imgs-hoder .upload-box-holder .image-upload-btn:hover .icon-holder {
    background-color: #000;
    color: #fff;
}
.admin-order-page .imgs-hoder .upload-box-holder .image-upload-btn:hover .upload-text {
    color: #000;
}
.admin-order-page .imgs-hoder .uploaded-img-box .img-holder img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.admin-order-page .imgs-hoder .img-inputs-holder .label-text {
    width: 38px;
}
.admin-order-page .imgs-hoder .img-inputs-holder input {
    border-radius: 0.4rem;
    background: #fff;
    color: #000000;
    text-indent: initial;
    caret-color: var(--purple-color);
    font-size: 14px;
    height: initial;
}
.admin-order-page .imgs-hoder .img-inputs-holder .img_style {
    object-fit: cover;
}
.admin-order-page .previw-image {
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}
.admin-order-page .previw-image .previw-image-content {
    width: 100%;
    height: 100%;
}
.admin-order-page .previw-image .previw-image-content img {
    max-width: 100%;
    max-height: 100%;
}
/* End Order Page */
.order-all-status-holder select:focus,
.order-all-search-holder input:focus {
    border-color: var(--purple-color);
    box-shadow: none;
}
/* Start Variables */
:root {
    /* ====== Colors ====== */
    --purple-color: #000;
    --purple-light-color: #F0EDFE;
    --purple-dark-color: #00106d;
    --hover-color: #412582;
    --main-button-color: #6355ff;
    --main-button-hover-color: #5200ff;
    --orange-color: #FFAB2F;
    --blue-color: #0D70FB;
    --black-text-color: #404145;
    --gray-text-color: #74767e;
    --background-color: #E7E7E7;
    --gray-bg-color: #F5F4F9;
    --light-green-color: #1dbf73;

    /* ====== Transition ====== */
    --tran-03: all 0.3s ease;
    --tran-04: all 0.4s ease;
    --tran-05: all 0.5s ease;
}
/* End Variables */
.authentication-page .authentication-content {
    /*background-color: var(--purple-color);*/
}

.authentication-page .authentication-content input:not(.react-international-phone-input) {
    border-radius: 0.75rem;
    background: #fff;
    color: #000000;
    height: 56px;
    text-indent: 25px;
    caret-color: var(--purple-color);
    font-size: 14px;
}

.authentication-page .authentication-content input.react-international-phone-input {
    border-radius: 0 0.75rem 0.75rem 0;
    background: #fff;
    color: #000000;
    height: 56px;
    caret-color: var(--purple-color);
    font-size: 14px;
    width: 100%;
}

.authentication-page .authentication-content .react-international-phone-country-selector-button {
    border-radius: 0.75rem 0 0 0.75rem;
    background: #fff;
    color: #000000;
    height: 56px;
    caret-color: var(--purple-color);
    font-size: 14px;
    padding: 0 15px;
}

.authentication-page .authentication-content input::placeholder {
    color: var(--gray-text-color);
}

.authentication-page .authentication-content input:focus {
    outline: 0;
    border-color: var(--purple-color);
    box-shadow: none;
}

.authentication-page .authentication-content .submit-button {
    background-color: var(--purple-color);
    color: #ffffff;
    height: 56px;
    border-radius: 0.75rem;
    transition: 0.3s;
}

.authentication-page .authentication-content .submit-button:hover {
    background-color: var(--hover-color);
}

.authentication-page .auth-link {
    color: var(--blue-color);
}

.authentication-page .auth-link:hover {
    color: var(--purple-color);
}
/* Start Header Section */
.home-page .home-page-header .logo-holder {
    width: 200px;
}
/* End Header Section */

/* Start Footer Section */
.home-page .footer-holder .social-icon,
.home-page .footer-holder .social-icons .social-icon {
    transition: var(--tran-03);
    color: var(--gray-text-color);
}
.home-page .footer-holder .social-icon:hover,
.home-page .footer-holder .social-icons .social-icon:hover {
    color: #fff;
}
/* End Footer Section */

/* Start Custom Order Page */
.custom-order-page .authentication-content .form-group textarea,
.custom-order-page .authentication-content .form-group input {
    border-radius: 0.4rem;
    background: #fff;
    color: #000000;
    text-indent: 25px;
    caret-color: var(--purple-color);
    font-size: 14px;
}
.custom-order-page .authentication-content .form-group select,
.custom-order-page .css-13cymwt-control,
.custom-order-page .authentication-content .form-group input {
    height: 40px;
}
.custom-order-page .authentication-content .custom-searchable-select,
.custom-order-page .css-13cymwt-control {
    border-color: #dee2e6;
    border-radius: 0.4rem;
    color: #000000;
    text-indent: 5px;
    caret-color: var(--purple-color);
    font-size: 14px;
}
.custom-order-page .authentication-content .custom-searchable-select.is-empty-input,
.custom-order-page .is-empty-input .css-13cymwt-control {
    border-color: rgb(220,53,69) !important;
}
.custom-order-page .authentication-content .react-international-phone-country-selector-button {
    border-radius: 0.75rem 0 0 0.75rem;
    background: #fff;
    color: #000000;
    height: 56px;
    caret-color: var(--purple-color);
    font-size: 14px;
    padding: 0 15px;
}

.custom-order-page .authentication-content input::placeholder,
.custom-order-page .order-notes-textarea::placeholder {
    color: #cdced3;
}
.custom-order-page .small-input-price::placeholder {
    font-size: 10px;
}

.custom-order-page .authentication-content textarea:focus,
.custom-order-page .authentication-content select:focus,
.custom-order-page .authentication-content .custom-searchable-select:focus,
.custom-order-page .authentication-content input:focus {
    outline: 0;
    border-color: var(--purple-color);
    box-shadow: none;
}
.custom-order-page .css-t3ipsp-control:hover,
.custom-order-page .css-t3ipsp-control {
    height: 40px;
    border-radius: 0.4rem;
    border-color: var(--purple-color);
    box-shadow: none;
}

.custom-order-page .authentication-content .submit-button {
    background-color: var(--purple-color);
    color: #ffffff;
    height: 40px;
    border-radius: 0.4rem;
    transition: 0.3s;
}

.custom-order-page .authentication-content .submit-button:hover {
    background-color: var(--hover-color);
}

.custom-order-page .auth-link {
    color: var(--blue-color);
}

.custom-order-page .auth-link:hover {
    color: var(--purple-color);
}
.custom-order-page .imgs-hoder .upload-box-holder .icon-holder {
    transition: var(--tran-03);
    background-color: var(--gray-text-color);
    color: #fff;
}
.custom-order-page .imgs-hoder .upload-box-holder .upload-text {
    transition: var(--tran-03);
    color: var(--gray-text-color);
}
.custom-order-page .imgs-hoder .upload-box-holder .image-upload-btn:hover .icon-holder {
    background-color: #000;
    color: #fff;
}
.custom-order-page .imgs-hoder .upload-box-holder .image-upload-btn:hover .upload-text {
    color: #000;
}
.custom-order-page .imgs-hoder .uploaded-img-box .img-holder img {
    width: 100%;
    height: 150px;
    object-fit: cover;
}
.custom-order-page .imgs-hoder .img-inputs-holder .label-text {
    width: 38px;
}
.custom-order-page .imgs-hoder .img-inputs-holder input {
    border-radius: 0.4rem;
    background: #fff;
    color: #000000;
    text-indent: initial;
    caret-color: var(--purple-color);
    font-size: 14px;
    height: initial;
}
.custom-order-page .imgs-hoder .img-inputs-holder .img_style {
    object-fit: cover;
}
.custom-order-page .previw-image {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
}
.custom-order-page .previw-image .previw-image-content {
    width: 100%;
    height: 100%;
}
.custom-order-page .previw-image .previw-image-content img {
    max-width: 100%;
    max-height: 100%;
}
/* End Custom Order Page */

/* End User Profile Page */

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1400px) {
}
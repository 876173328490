/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Start Global Rules */
*:hover {
    outline: none;
}

ul {
    list-style: none;
    padding: 0;
}

.c-pointer {
    cursor: pointer !important;
}

input {
    line-height: normal;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

html,
body,
#root,
.App {
    height: 100%;
}

#root {
    height: 100%;
    min-height: 100%;
}


body {
    background-color: var(--background-color);
    font-family: 'Inter','sans-serif';
    font-size: 1rem;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease;
}

::selection {
    background-color: var(--purple-color);
    color: #fff;
}

.fw-bold-500 {
    font-weight: 500;
}

.fw-bold-600 {
    font-weight: 600;
}

.main-text-color {
    color: var(--purple-color) !important;
}

.main-text-light-color {
    color: var(--purple-light-color) !important;
}

.main-dark-color {
    color: var(--purple-dark-color);
}

.main-bg-color {
    background-color: var(--purple-color) !important;
}

.main-bg-light-color {
    background-color: var(--purple-light-color) !important;
}

.black-text-color {
    color: var(--black-text-color);
}

.gray-text-color {
    color: var(--gray-text-color);
}

.gray-bg-color {
    background-color: var(--gray-bg-color);
}

.main-button-color {
    background-color: var(--main-button-color);
}

.main-button-color:hover {
    background-color: var(--main-button-hover-color);
}

.light-shadow {
    box-shadow: 0 0 4px rgba(0,29,109,.11);
}

.text-real-small {
    font-size: 12px !important;
}

.link-as-button {
    background-color: var(--main-button-color);
    color: #fff;
    cursor: pointer;
    transition: var(--tran-03);
}
.link-as-button:hover {
    background-color: var(--main-button-hover-color);
    color: #fff;
}
.light-hover-effect {
    background-color: #fcfcfd;
    transition: var(--tran-03);
}

.light-hover-effect:hover {
    background-color: var(--gray-bg-color);
}
.custom-description-holder * {
    max-width: 100% !important;
}
/* End Global Rules */